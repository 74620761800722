/* HeroSection.css */

.hero-container {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  transition: height 0.4s ease-in-out, transform 0.2s ease-out;
}





.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8));
  z-index: 1;
}

/* Text container styling */
.hero-text {
  position: relative;
  z-index: 2;
  text-align: center;
  max-width: 600px;
  color: #fff;
  padding: 20px;
  margin-top: -100px;
}

/* Enhanced text shadow for better visibility */
.animated-title {
  font-size: 4vw;
  font-weight: bold;
  margin-bottom: 15px;
  color: #ffdd57;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.9); /* Increased shadow for better contrast */
}

/* Headline for Mobile */
@media (max-width: 600px) {
  .animated-title {
    font-size: 10vw; /* Slightly smaller for mobile screens */
  }


  .animated-subtitle {
    font-size: 1.4rem; /* Decreased size for better fit */
  }

  .plant-prompt {
    font-size: 1.4rem; /* Slightly smaller prompt text for mobile */
  }
}

.animated-subtitle {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #e0e0e0;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8); /* Added shadow */
}

.plant-prompt {
  font-size: 1.4rem;
  color: #ff9900;
  margin-bottom: 20px;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8); /* Enhanced shadow for visibility */
}


/* Button group styling */
.button-group {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
}

.yes-button {
  background-color: #4caf50;
  color: #fff;
  padding: 14px 28px;
  border: none;
  border-radius: 5px;
  font-size: 1.0rem; /* Slightly increased size */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.yes-button:hover {
  background-color: #388e3c;
}

.no-button {
  background-color: #ffeb3b;
  color: #333;
  padding: 14px 28px;
  border: none;
  border-radius: 5px;
  font-size: 1.0rem; /* Slightly increased size */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.no-button:hover {
  background-color: #fdd835;
}

/* Container for the response message with a fixed height */
.response-message-container {
  height: 50px; /* Pre-allocated height to avoid layout shift */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Response message styling */
.response-message {
  font-size: 1.4rem;
  color: #ffdd57;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s;
}

.response-message.visible {
  opacity: 1;
  visibility: visible;
}



/* CTA button styling */
.cta-button {
  background: linear-gradient(90deg, #ff6600, #ff9900);
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 1.4rem;
  cursor: pointer;
  margin-top: 20px;
}

.cta-button:hover {
  background: linear-gradient(90deg, #ff9900, #ff6600);
  box-shadow: 0 4px 20px rgba(255, 153, 0, 0.7);
}
