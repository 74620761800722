/* Ideas.css */

.ideas-section {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 60px 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .idea-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    padding: 30px;
    border-radius: 15px;
    background: linear-gradient(145deg, #f0f0f0, #cfcfcf);
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1), -8px -8px 16px rgba(255, 255, 255, 0.7);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .idea-card:hover {
    transform: scale(1.02);
    box-shadow: 12px 12px 24px rgba(0, 0, 0, 0.15), -12px -12px 24px rgba(255, 255, 255, 0.8);
  }
  
  .idea-content {
    flex: 1;
  }
  
  .idea-content h3 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 15px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .idea-content p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
  }
  
  .idea-image {
    flex: 0 0 200px;
    max-width: 200px;
    position: relative;
  }
  
  .idea-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
  }
  
  .idea-image img:hover {
    transform: scale(1.05);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .idea-card {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .idea-image {
      max-width: 100%;
      margin-top: 20px;
    }
  
    .idea-content h3 {
      font-size: 1.8rem;
    }
  
    .idea-content p {
      font-size: 1rem;
    }
  }
  