/* Global settings to prevent horizontal overflow */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  max-width: 100vw;
  width: 100%;
}

.container {
  max-width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  padding-top: 70px; /* Adds space at the top to prevent content overlap with fixed navbar */
}


/* Navbar base styles */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(51, 51, 51, 0.9);
  backdrop-filter: blur(10px);
  color: white;
  padding: 5px 20px; /* Reduce top and bottom padding */
  z-index: 1000;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}


/* Logo and HerbFarmer text */
.navbar-logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.navbar-logo img {
  width: 30px; /* Slightly larger for better visibility */
  height: auto;
}

.navbar-logo span {
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
}

/* Navbar Links */
.navbar-links {
  display: flex;
  list-style: none;
  gap: 15px;
}

.navbar-link {
  color: white;
  font-size: 1.1rem;
  text-decoration: none;
  position: relative;
  transition: color 0.3s ease;
}

.navbar-link:hover,
.navbar-link.active {
  color: #ff6600;
  font-weight: bold;
}

/* Hamburger menu */
.hamburger {
  display: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.hamburger:hover {
  transform: scale(1.1);
}

/* Mobile dropdown menu */
@media (max-width: 700px) {
  .hamburger {
    display: block;
  }

  .navbar-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px; /* Positioning dropdown below the navbar */
    left: 50%;
    transform: translateX(-50%);
    background: rgba(51, 51, 51, 0.95);
    padding: 20px 0;
    width: 80%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }

  .navbar-links.active {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbar-item {
    padding: 10px 0;
    text-align: center;
    width: 100%;
  }

  /* Adjusting text size in mobile view */
  .navbar-link {
    font-size: 1rem;
  }

  .navbar-logo span {
    font-size: 1rem;
  }
}
