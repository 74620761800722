/* AboutMe.css */

/* Container styling */
.about-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 40px;
  background: #2a2a2a;
  color: #f5f5f5;
  border-radius: 15px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.5);
  margin: 80px auto;
  max-width: 85%;
  transition: transform 0.3s ease-in-out;
  
}

.about-container:hover {
  transform: scale(1.02);
}

/* Avatar styling */
.about-image img {
  width: 180px;
  height: auto;
  border-radius: 50%;
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.3);
}

/* Text styling with improved readability */
.about-text {
  flex: 1;
  margin-left: 20px;
  color: #e0e0e0;
  font-weight: 500;
  line-height: 1.6;
  animation: fadeInUp 1s ease-out;
}

.about-text h2 {
  font-size: 2.2rem;
  margin-bottom: 15px;
  color: #ff6600;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

.about-text p {
  font-size: 1.1rem;
  line-height: 1.75; /* Increase line height for better spacing */
  margin: 15px 0;
  color: #d1d1d1; /* Softer color for less strain */
  font-weight: 400; /* Reduced font weight for improved readability */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .about-image img {
    margin-bottom: 20px;
    width: 120px;
  }

  .about-text {
    margin-left: 0;
    font-size: 1rem;
  }

  .about-text h2 {
    font-size: 2rem;
  }

  .about-text p {
    font-size: 1rem;
    line-height: 1.6;
  }
}

/* Animation Keyframe */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

