/* Enhanced Footer.css */

.footer-container {
  background: linear-gradient(145deg, #1b1b1b, #2d2d2d);
  color: #f0f0f0;
  padding: 60px 20px;
  text-align: center;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.3);
}

.footer-hero {
  margin-bottom: 40px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.footer-hero h2 {
  font-size: 2.5rem;
  color: #ff9900;
  margin-bottom: 10px;
}

.footer-hero p {
  font-size: 1.2rem;
  color: #cccccc;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
}

/* Footer Section */
.footer-section {
  flex: 1;
  min-width: 200px;
  margin-bottom: 20px; /* Restored original spacing */
}

/* Contact Links without Hyperlink Styling */
.footer-section a {
  color: inherit;
  text-decoration: none;
}

.footer-section a:hover {
  color: #ff9900; /* Optional hover color */
  text-decoration: none;
}

/* Quick Links Styling */
.footer-section h3 {
  font-size: 1.8rem;
  color: #ff9900;
  margin-bottom: 15px;
  text-align: center;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  text-align: center;
}

.footer-section li {
  margin-bottom: 12px;
}

/* Quick Links - Enhanced Link Styles */
.footer-section li a {
  color: #e0e0e0;
  font-size: 1.1rem;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
}

.footer-section li a:hover {
  color: #ff9900;
  transform: scale(1.05);
}

/* Social Icons Section */
.social-icons {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 15px;
}

.social-icons a {
  font-size: 1.8rem;
  color: #f0f0f0;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icons a:hover {
  transform: scale(1.2);
  color: #ff9900;
}

/* Footer Bottom and Version Text */
.footer-bottom {
  margin-top: 40px;
  font-size: 0.9rem;
  color: #aaaaaa;
}

.footer-version {
  font-size: 0.9rem;
  color: #aaaaaa;
  margin-bottom: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-hero h2 {
    font-size: 2rem;
  }

  .footer-section h3 {
    font-size: 1.6rem;
  }

  .footer-section li a {
    font-size: 1rem;
  }
}
