/* App.css */

/* Global Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

h1 {
  color: #333;
  font-size: 2.5em;
  text-align: center;
  margin-top: 50px;
}

p {
  font-size: 1.2em;
  color: #666;
}

/* Section Styles with Transition Fix */
section {
  opacity: 1; /* Set to 1 by default so sections are visible */
  transform: translateY(0); /* Prevent initial downward shift */
  transition: opacity 0.8s ease, transform 0.8s ease;
}

section:target {
  opacity: 1;
  transform: translateY(0); /* Keep transition but ensure no disappearance */
}

html {
  scroll-behavior: smooth;
}
