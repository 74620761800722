/* MyProjects.css */

.projects-section {
  padding-bottom: 60px;
  background-color: #f5f5f5;
  text-align: center;
}

.containerProjects {
  padding-top: 20px;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
}

/* Grid layout for the project cards */
.projects-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.project-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  min-height: 580px; /* Set minimum height instead of fixed height */
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.project-title {
  font-size: 1.5rem;
  color: #ff6600;
  text-align: center;
}

.project-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

.project-description {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
  text-align: center;
  flex-grow: 1;
  margin-bottom: 15px;
  min-height: 150px; /* Ensure consistent height for the description */
}

.project-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ff6600;
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  margin-top: auto; /* Pushes button to bottom */
  margin-bottom: 10px; /* Consistent bottom padding */
}

.project-button:hover {
  background-color: #ff9900;
}

.project-button:focus {
  outline: none;
}

/* MyProjects.css */

.gradient-button {
  background: linear-gradient(to right, #f97316, #ef4444);
  color: #fff;
  padding: 10px 20px;
  border-radius: 12px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.gradient-button:hover {
  background: linear-gradient(to right, #fb923c, #f87171);
}
